<template>
  <div class="order-detail-container">
    <div class="button-back ">
      <el-button @click="() => { $router.go(-1); }" size="small" class="back_btn">
        <img src="@/assets/back.png" alt="">
      </el-button>
    </div>
    <div class="cont">
      <br />
      <br />
      <p>活动名称：{{ infoData.name }}</p>
      <p>开始时间：{{ infoData.start_time | secondUnixToDate }}</p>
      <p>结束时间：{{ infoData.end_time | secondUnixToDate }}</p>
      <p>
        活动商品：
        <el-button size="mini" type="primary" @click="showDialog = true">选择商品</el-button>
        <el-button size="mini" type="primary" @click="batchCancel(1)">批量取消 </el-button>
        <el-button size="mini" type="primary" @click="batchCancel(0)">批量设置拼团价 </el-button>
      </p>
      <p>提示：1、商品列表可拖拽调整位置；2、此处商品顺序和移动端前台拼团活动商品列表中排序一致；</p>
      <el-form :model="batchForm" :rules="batchFormRule" ref="batchForm">
        <en-table-layout :tableData="batchForm.goodsData" style="width: 100%" ref="selectTableWrap"
          @selection-change="selectChange" :row-key="(row) => row.id">
          <template slot="table-columns">
            <el-table-column :key="'selection'" :reserve-selection="true" type="selection" width="50" />
            <el-table-column label="商品图片" width="100" class-name="goods-cover-wrapper">
              <template slot-scope="{ row }">
                <el-popover placement="right" trigger="hover">
                  <img :src="row.thumbnail" alt style="width: 300px" />
                  <img :src="row.thumbnail" class="goods-cover" alt slot="reference" />
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="商品名称" :show-overflow-tooltip="true" width="200">
              <template slot-scope="scope">
                <span v-html="scope.row.goods_name"></span>
              </template>
            </el-table-column>
            <el-table-column label="商品编号" :show-overflow-tooltip="true" width="120">
              <template slot-scope="{ row }">
                <span v-html="row.self_operated !== 1 && row.goods_source === 1 ? row.ext_goods_id : row.sn"></span>
              </template>
            </el-table-column>
            <el-table-column key="revisePrice" label="原销售价" width="100" prop="revisePrice">
              <template slot-scope="scope">{{ scope.row.revise_price | unitPrice("￥") }}</template>
            </el-table-column>
            <el-table-column key="mktprice" label="拼团价" prop="market">
              <template slot-scope="scope">
                <el-form-item :prop="`goodsData.${scope.$index}.group_activity_price`"
                  :rules="batchFormRule.group_activity_price" style="margin:0" :inline-message="true">
                  <el-input size="small" clearable placeholder="请输入" v-model.trim="scope.row.group_activity_price">
                    <template slot="prepend">￥</template>
                  </el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="库存" width="100">
              <!-- 商品来源，0普通商品，1京东商品 -->
              <template slot-scope="scope">
                <template v-if="scope.row.goods_source === 1">{{ scope.row.enable_quantity > 0 ? "有货" : "无货"
                }}</template>
                <template v-else>{{ scope.row.enable_quantity || 0 }}件</template>
              </template>
            </el-table-column>
            <el-table-column label="状态" width="100" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.shop_goods_status === 0 || scope.row.disabled === 0">
                  已下架<br /><span style="color: red">（已失效）</span>
                </div>
                <span v-else>已上架</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120" align="center">
              <template slot-scope="scope">
                <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">取消参加</el-button>
              </template>
            </el-table-column>
            <!-- </el-table> -->
          </template>
        </en-table-layout>
      </el-form>
      <!--底部-->
      <div class="footer">
        <el-button-group>
          <el-button :loading="createGoodsButtonLoading" type="primary" size="small" @click="aboveGoods">保存</el-button>
        </el-button-group>
      </div>
    </div>
    <en-goods-picker-fx :pickupGift="1" isGroup :show="showDialog" :showChooseBtn="false"
      :default-data="batchForm.goodsData" @close="showDialog = false" @chooseAll="allChoose" />
    <x-dialog :proxy="settingDiscountRate">
      <div style="padding:10px">
        <el-form :model="editForm" ref="editForm" :rules="editFormRule" label-width="80px" size="small">
          <el-form-item label="拼团价" prop="group_activity_price">
            <div style="display: flex;">
              按照【销售价】下降：
              <el-input style="width: 150px;" type="text" maxlength="100" v-model.trim="editForm.group_activity_price">
                <template slot="append">%</template>
              </el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </x-dialog>
    <!--活动内商品及活动时间是否和已有活动重叠 dialog-->
    <el-dialog-x-dialog title="提示" :visible.sync="dialogGoodsAuditVisible" width="400px" :close-on-click-modal="false"
      :close-on-press-escape="false">
      <div class="dialog-content">
        <p>以下商品在同时间段已经参与了其他拼团促销活动，请重新挑选。</p>
        <p class="goods_name" v-for="(item, index) in errorGoods" :key="index">{{ item.goods_name }}</p>
        <p class="foot_btn"><el-button type="primary" @click="downloadFile" size="small">下载问题文件</el-button></p>
      </div>
    </el-dialog-x-dialog>
  </div>
</template>
<script>

import Sortable from 'sortablejs';
import * as API_Auth from '@/api/auth'
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import { Foundation } from "@/../ui-utils";
import { handleDownload } from "@/utils";
export default {
  name: 'group-buying-goods',
  components: {
    EnTableLayout,
    XDialog
  },
  data () {
    return {
      dialogGoodsAuditVisible: false,//错误信息弹窗
      errorGoods: [],//错误商品集合
      batchForm: {
        goodsData: [],
      },
      batchFormRule: {
        group_activity_price: [
          { required: true, message: '请填写拼团价', trigger: 'blur' },
          {
            validator: async (rule, value, callback) => {
              const parent = Number(rule.field.split('.')[1])
              let resData = this.batchForm.goodsData[parent]
              const reg = /^(0|[1-9]\d*)(\.\d{1,2})?$/
              if (isNaN(value) || value <= 0 || value > 99999999 || !reg.test(value)) {
                callback(new Error('请输入小数点后两位的正数'))
              } else if (Number(resData.revise_price) < Number(value)) {
                callback(new Error('不可大于销售价'))
              } else {
                callback()
              }
            }
          }
        ]
      },
      createGoodsButtonLoading: false,
      infoData: {
        id: '',
        name: '',
        start_time: '',
        end_time: '',
      },

      parentId: '',
      /** 选择器相关 */
      showDialog: false,
      //选中得勾选项
      selectCarmiTable: [],
      settingDiscountRate: $xDialog.create({
        title: "批量操作",
        width: "40vw",
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: this.settingForm
      }),
      editForm: {//拼团价设置
        group_activity_price: ''
      },
      editFormRule: {
        group_activity_price: [
          { required: true, message: '请填写拼团价', trigger: 'blur' },
          {
            validator: async (rule, value, callback) => {
              const reg = /^(0|[1-9]\d*)(\.\d{1,2})?$/
              if (isNaN(value) || value < 0 || value > 99.99 || !reg.test(value)) {
                callback(new Error('仅允许输入0~99.99，且最多到小数点后两位的正数'))
              } else {
                callback()
              }
            }
          }
        ]
      },
    }
  },
  mounted () {
    this.parentId = this.$route.query.id
    this.handleView();
    this.rowDrop();
  },
  methods: {
    // handleBlur() {
    //   const inputValue = parseFloat(this.inputValue);
    //   if (isNaN(inputValue) || inputValue <= 0 || inputValue > 99.99) {
    //     this.inputValue = '99.99';
    //   }
    // },
    /**复选框选中事件 */
    selectChange (val) {
      this.selectCarmiTable = val;
    },
    // 行拖拽
    async rowDrop () {
      // 此时找到的元素是要拖拽元素的父容器
      await new Promise(resolve => setTimeout(resolve, 0))
      const el = this.$refs.selectTableWrap.$el
      const tbody = el.querySelector('.el-table__body-wrapper tbody');
      const _this = this;
      Sortable.create(tbody, {
        //  指定父元素下可被拖拽的子元素
        draggable: ".draggable .el-table__row",
        onEnd ({ newIndex, oldIndex }) {
          const currRow = _this.batchForm.goodsData.splice(oldIndex, 1)[0];
          _this.batchForm.goodsData.splice(newIndex, 0, currRow);
        }
      });
    },
    /** 保存商品选择器选择的商品 */
    allChoose (list) {
      const originGoodsId = this.batchForm.goodsData.map((item) => {
        return item.goods_id;
      });
      list.forEach((i) => {
        if (originGoodsId.indexOf(i.goods_id) === -1) {
          this.$set(i, "group_activity_price", i.revise_price);
          this.batchForm.goodsData.push(i);
        }
      });
    },
    /** 查看活动 */
    async handleView () {
      this.infoData = {}
      this.batchForm.goodsData = []
      API_Auth.getGroupDetail(this.parentId).then((res) => {
        this.infoData = {
          id: res.id,
          name: res.name,
          start_time: res.start_time,
          end_time: res.end_time
        }
      })
      API_Auth.getGroupGoodsList(this.parentId).then(respose => {
        respose.forEach(item => {
          item.revise_price = item.mkt_price
          item.goods_name = item.name
        })
        setTimeout(() => {
          this.batchForm.goodsData = respose
        }, 500)
      })
    },
    /**取消参加 */
    handleDelete (index, row) {
      this.batchForm.goodsData.splice(index, 1);
    },
    /**批量操作 */
    batchCancel (num) {
      let arr = [];
      if (
        this.selectCarmiTable &&
        this.selectCarmiTable.length &&
        this.selectCarmiTable.length > 0
      ) {
        this.selectCarmiTable.map((item) => {
          arr.push(item.goods_id);
        });
      } else {
        this.$message.error("请先勾选商品数据");
        return;
      }
      if (num === 0) {//批量设置折扣率
        this.settingDiscountRate.display();
      } else {//批量删除
        this.batchForm.goodsData = this.batchForm.goodsData.filter(item => !arr.includes(item.goods_id))
        this.selectCarmiTable = []
        this.$refs.selectTableWrap.$refs.table.clearSelection();
      }
    },
    /**批量设置拼团价 */
    settingForm () {
      const { promise, resolve, reject } = Promise.withResolvers();
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          let arr = []
          this.selectCarmiTable.map((item) => {
            arr.push(item.id);
          });
          this.batchForm.goodsData.forEach(item => {
            if (arr.includes(item.id)) {
              const a1 = Foundation.rideitionAdd(this.editForm.group_activity_price, 100)
              const a2 = Foundation.subtraction(1, a1)
              item.group_activity_price = (Foundation.rideitionNumber(item.revise_price, a2)).toFixed(2)
            }
          })
          resolve(true)
        } else {
          reject(false)
        }
      });
      return promise;
    },
    /**保存 */
    aboveGoods () {
      this.$refs.batchForm.validate(valid => {
        if (valid) {
          this.createGoodsButtonLoading = true;
          let goodsData = [
            ...this.batchForm.goodsData
          ]
          goodsData.forEach(item => {
            if (!item.shop_goods_id) {
              item.shop_goods_id = item.id
            }
          })
          let newarray = []
          newarray = goodsData.map(item => {
            return {
              shop_id: item.shop_id,
              shop_goods_id: item.shop_goods_id,
              group_activity_id: this.parentId,
              goods_id: item.goods_id,
              sequence: item.sequence,
              group_activity_price: item.group_activity_price
            }
          })
          API_Auth.saveGroupGoods(this.parentId, newarray).then(res => {
            if (res.success) {
              this.$message.success('保存成功！');
              this.$router.go(-1);
            } else {
              this.createGoodsButtonLoading = false;
              this.dialogGoodsAuditVisible = true;
              this.errorGoods = res.data
            }
          }).catch(() => {
            this.createGoodsButtonLoading = false;
          })

        }
      })
    },
    /**下载问题文件 */
    downloadFile () {
      let filterVals = [
        'goods_sn',//row.self_operated !== 1 && row.goods_source === 1? row.ext_goods_id: row.sn
        'goods_name',
        'mkt_price',
        'group_activity_price',
        'activity_name'
      ];
      let tHeaders = [
        '商品编号',
        '商品名称',
        '销售价',
        '拼团价',
        '活动名称'
      ];
      this.errorGoods.map((item, index) => {
        item.create_time = Foundation.unixToDate(
          item.create_time,
          'yyyy-MM-dd hh:mm:ss'
        );
        item.mobile = (item.mobile || '') + (item.mobile && item.login_account ? '\n' : '') + (item.login_account ? item.login_account : '')
      });
      handleDownload(this.errorGoods, tHeaders, filterVals, '问题文件');
    }
  }
}
</script>
<style lang="scss" scoped>
.order-detail-container {
  background-color: #fff;

  .cont {
    padding: 20px;
  }

  /** 底部步骤 */
  .footer {
    width: 85.5%;
    padding: 10px;
    background-color: #fff;
    position: fixed;
    bottom: 0px;
    // left: 280px;
    text-align: center;
    z-index: 999;
    border-top: 1px solid #eee;
    left: 13.8%;
    right: 2%;
  }

  .button-back {
    height: 40px;
    padding: 20px;
    float: right;

    .back_btn {
      width: 36px;
      height: 36px;
      background-color: #1a43a9;
      border-radius: 50%;

      img {
        width: 10px;
        height: 20px;
        position: relative;
        top: -2px;
        left: -4px;
      }
    }
  }
}

.dialog-content {
  padding: 0 10px;

  .goods_name {
    font-size: 12px;
    margin: 0;
  }

  .foot_btn {
    text-align: center;
    margin-top: 10px;
  }
}

/deep/ .el-form-item__error--inline {
  display: block;
}
</style>